import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { useContentfulSection, useMeta, blogFilters } from "utils"
import { Layout, Seo, TextGroup } from "components/common"
import { FeaturedPost, Posts } from "src/components/post"
import { PrimaryNav, MobileNav } from "src/components/navigation"

const BlogPage = () => {
  const { featured, blogPosts, page } = useStaticQuery(QUERY)
  const { blog_filter, blog_hero } = useContentfulSection(page.sections)
  const meta = useMeta(page)

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />
      <StyledBlogPost>
        <TextGroup
          className="blog_hero"
          title={blog_hero.title}
          subtitle={blog_hero.subtitle}
          titleAs="h1"
        />
        <FeaturedPost {...featured} hat="Featured post" slugBase="/blog" />
      </StyledBlogPost>
      <Posts
        posts={blogPosts}
        title={blog_filter.title}
        filters={blogFilters}
        slugBase="/blog"
      />
    </Layout>
  )
}

const StyledBlogPost = styled.div`
  .blog_hero {
    --tg-text-align: center;
    margin-top: var(--sp-mobile-nav);
    /* margin-top: var(--sp-nav); */
  }
`

const QUERY = graphql`
  {
    page: contentfulPage(pageId: { eq: "blog" }) {
      ...Page
    }
    featured: contentfulBlogPost(featuredPost: { eq: true }) {
      ...BlogPost
    }
    blogPosts: allContentfulBlogPost(
      filter: { slug: { ne: "blog-placeholder-dont-delete" } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
  }
`

export default BlogPage
